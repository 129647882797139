import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import NewsCard from "../NewsCard";

const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const RelatedNews = () => {
  const [news, setNews] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    socket.emit("retrieveHeadlineNews"); //on connection, request 16 news headlines
    socket.on("16NewsHeadlinesRetrieved", (dataObject) => {
      setNews(dataObject);
    });
    return () => {
      socket.off("16NewsHeadlinesRetrieved");
    };
  }, []);

  return (
    <div>
   <Swiper
  spaceBetween={30}
  slidesPerView={1} // Default value
//   onSlideChange={() => console.log("slide change")}
  onSwiper={(swiper) => console.log(swiper)}
  breakpoints={{
    // Breakpoints for different screen sizes
    640: {
      slidesPerView: 2, // 2 slides for screens >= 640px
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3, // 3 slides for screens >= 768px
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 4, // 4 slides for screens >= 1024px
      spaceBetween: 40,
    },
  }}
>
  {news?.map((item) => (
    <SwiperSlide key={item._id}>
      <NewsCard items={item}></NewsCard>
    </SwiperSlide>
    ))}
    </Swiper>

          <div >
              <h1 className="text-7xl text-center mt-[-50px] pb-8">....</h1>
          </div>

    </div>
  );
};

export default RelatedNews;
