import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaFacebookF, FaPhoneAlt, FaYoutube } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import './news.css';

import io from "socket.io-client";
import RelatedNews from "./Related News/RelatedNews";
const socket = io.connect("https://mayapurapp.ap-1.evennode.com");

const NewsDetls = () => {
  const [api, setApi] = useState([]);
  const [item, setItem] = useState([]);
      const { pathname } = useLocation();
      const location = useLocation();
    
      useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const pathParts = location.pathname.split("/");
  const id = pathParts[2];
  const thisId = "A" + id;


const date_stamp = api?.date_stamp

const dates = new Date(date_stamp); // convert it to a Date object
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dateInBengali = dates.toLocaleDateString("bn-IN", options); // convert the date to Bengali
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    const backendDate = new Date(date_stamp);

    const banglaDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

    const convertToBanglaDigits = (number) => {
      return number
        .toString()
        .split("")
        .map((digit) => banglaDigits[parseInt(digit)])
        .join("");
    };

    const calculateTimeDifference = () => {
      const now = new Date();
      const diffInMilliseconds = now - backendDate;

      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays < 10) {
        if (diffInDays > 0) {
          return `${convertToBanglaDigits(diffInDays)} দিন আগে`;
        } else if (diffInHours > 0) {
          return `${convertToBanglaDigits(diffInHours)} ঘন্টা আগে`;
        } else if (diffInMinutes > 0) {
          return `${convertToBanglaDigits(diffInMinutes)} মিনিট আগে`;
        } else {
          return `${convertToBanglaDigits(diffInSeconds)} সেকেন্ড আগে`;
        }
      } else {
        return `${dateInBengali}`;
      }
    };

    setTimeDifference(calculateTimeDifference());

    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [dateInBengali,date_stamp]);

  useEffect(() => {
    socket.emit("retrieveNewsArticle", thisId);
    socket.on("newsArticleRetrieved", (data) => {
      setApi(data);
    });
    return () => {
      socket.off("newsArticleRetrieved");
    };
  }, [thisId]);

  // make news title
  useEffect(() => {
    socket.emit("retrieveNewsHeadlineOne", id);
    socket.on("retrieveNewsHeadlineOneRetrieved", (data) => {
      setItem(data);
    });
    return () => {
      socket.off("retrieveNewsHeadlineOneRetrieved");
    };
  }, [id]);

  let youtubeEm = "";
  for (const fruit of item) {
  const youtube_embed = fruit?.youtube_embed

  if (youtube_embed?.length > 1) {
    const youtubeEmbedUrl = youtube_embed.match(/src="(.*?)"/)[1];
    // eslint-disable-next-line no-unused-vars
    youtubeEm = youtubeEmbedUrl;
  }
  }

   const dt = [
    {
      id: "aaa",
      title: "অনুসরণ করুন: গীতা স্টাডি কোর্স",
      desc: "বি: দ্র: আপনি যদি গীতা ও ভাগবতের উপর ঘরে বসে নিয়মিত অধ্যায়ন বা কোর্স করতে চান তাহলে যোগাযোগ করুন",
      web: false,
      youtube: "https://www.youtube.com/c/BhaktivedantaGitaAcademyOfficial",
      facebook: "https://www.facebook.com/BGAMayapur",
      email: " gitaacademy@gmail.com - ঘরে বসে কোর্স করতে জানুন",
      ph: " +৯১ ৭৬৯৯৩২৭৮৭৪ - ফোন করে কোর্সে অংশগ্রহন করুন",
      image: "https://www.iskconbangla.com/iframe/aaa/img/gitacourse.png",
    },

    {
      id: "bbb",
      title: "অনুসরণ করুন: নামহট্ট",
      desc: "বি: দ্র: আপনার এলাকায় কাছাকাছি আধ্যাত্মিক শিক্ষা ও অনুষ্ঠান পেতে যোগাযোগ করুন",
      web: "https://iskconnamahatta.news/",
      youtube: "https://www.youtube.com/c/ISKCONNamahattaNews",
      facebook:
        "https://www.facebook.com/100064630309079/posts/667371621304502/",
      email: "mailto:namahatta.news@gmail.com",
      ph: "+৯১ ৭৪৩১০ ৯৮৭৪৭",
      image: "https://www.iskconbangla.com/iframe/bbb/img/namhatta.png",
    },

    {
      id: "ccc",
      title: "অনুসরণ করুন: সংকীর্তন",
      desc: "বি: দ্র: আপনি যদি ঘরে বসে শ্রীল প্রভুপাদের দিব্য গ্রন্থ পেতে চান তাহলে যোগাযোগ করুন",
      web: "https://www.mayapur.com/about-mayapur/preaching/book-distribution/",
      youtube: "https://www.youtube.com/watch?v=lDiHWvJpUlU",
      facebook: "https://www.facebook.com/iskconmayapursankirtan",
      email: " mayapuronlinestore@gmail.com",
      ph: "+৯১ ৭৪৩১০ ৯৮৭৪৭",
      image:
        "https://www.iskconbangla.com/iframe/ccc/img/sankirtan%20160px.png",
    },

    {
      id: "ddd",
      title: "অনুসরণ করুন: ট্রাইবেল কেয়ার",
      desc: "বি: দ্র: আপনি জনজাতি অন্তর্ভুক্ত হন বা তাদের সাহায্য করতে চান তাহলে যোগাযোগ করুন",
      web: "https://www.indiatribalcare.org/",
      youtube: "https://www.youtube.com/channel/UCqRd9aj-_8zfc0SvmG9xIAg",
      facebook: "https://www.facebook.com/indiatribalcare/",
      email: " info@itct.org.in",
      ph: false,
      image: "https://www.iskconbangla.com/iframe/ddd/img/tribalcare.png",
    },

    {
      id: "eee",
      title: "অনুসরণ করুন: মায়াপুর ভাক্তিবৃক্ষ",
      desc: "বি: দ্র: আপনি যদি শ্রীধাম মায়াপুরের কাছাকছি থাকেন তাহলে যোগাযোগ করুন",
      web: false,
      youtube: "https://www.youtube.com/channel/UCqLLJvJqR0pM368FdxgWzdw",
      facebook: "https://www.facebook.com/ISKCON.BV/",
      email: "bhaktivriksha@gmail.com",
      ph: "+৯১ ৮১৪৫৭৮৬৭৭৭",
      image: "https://www.iskconbangla.com/iframe/eee/img/bhaktivriksha.png",
    },

    {
      id: "fff",
      title: "অনুসরণ করুন: ভক্তিবেদান্ত বুক ট্রাষ্ট",
      desc: "বি: দ্র: আপনি যদি ইসকনের দিব্য গ্রন্থগুলি সংগ্রহ করতে চান তাহলে যোগাযোগ করুন",
      web: "https://bbt.org/",
      youtube: "https://www.youtube.com/c/BhaktivedantaBookTrust",
      facebook: "https://www.facebook.com/srilaprabhupadabbtnepal/",
      email: "sadhuseva@icloud.com",
      ph: "+৯১ ২২২৬২০২৯২১",
      image: "https://www.iskconbangla.com/iframe/fff/img/bbt.png",
    },

    {
      id: "ggg",
      title: "অনুসরণ করুন: মায়াপুর ম্যনেজমেন্ট",
      desc: "বি: দ্র: আপনি যদি শ্রীধাম মায়াপুর মন্দিরের কোন তথ্য জানতে চান তাহলে যোগাযোগ করুন",
      web: "https://mcs.org.in/node/3",
      youtube: "https://www.facebook.com/mayapur.sevaks",
      facebook: "https://www.facebook.com/mayapur.sevaks",
      email: "mcs.office@pamho.net",
      ph: "+৯১ ৯৪৭৪৭৩২৮১২",
      image: "https://www.iskconbangla.com/iframe/ggg/img/mcs.png",
    },

    {
      id: "hhh",
      title: "অনুসরণ করুন: মায়াপুর টিভি",
      desc: "বি: দ্র: আপনি যদি নিয়মিত ইসকন মন্দিরের আরতি, প্রবচন এবং উৎসব সরাসরি দেখতে ছান তাহলে যোগাযোগ করুন",
      web: "https://www.mayapur.tv/",
      youtube: "https://www.youtube.com/channel/UCypj9Vvizo4cCERfDFIG3zw",
      facebook: "https://www.facebook.com/Mayapur.tv/",
      email: "antardwip.mtv@gmail.com",
      ph: "০৩৪৭২ ২৪৫১৪০",
      image: "https://www.iskconbangla.com/iframe/hhh/img/mtv.png",
    },

    {
      id: "iii",
      title: "অনুসরণ করুন: SAFE",
      desc: "বি: দ্র: আপনি যদি ছাত্র হয়ে থাকেন তাহলে আপনার এলাকায় নিয়মিত ক্লাসের জন্য যোগাযোগ করুন",
      web: "https://www.youtube.com/channel/UCHJ1jeJOLP1_NgbTHw99HZg",
      youtube: "https://www.youtube.com/channel/UCHJ1jeJOLP1_NgbTHw99HZg",
      facebook: "https://www.facebook.com/groups/331897540324272/",
      email: " abd@pamho.net",
      ph: "০৩৪৭২ ২৪৫৪৯৮",
      image: "https://www.iskconbangla.com/iframe/iii/img/safe.png",
    },

    {
      id: "jjj",
      title: "অনুসরণ করুন: মায়াপুর গোশালা",
      desc: "বি: দ্র: আপনি যদি গো-সেবা করতে চান তাহলে যোগাযোগ করুন",
      web: "https://srimayapurgoshala.com/",
      youtube: "https://www.youtube.com/channel/UCrR_-QqOjeTjOMRkIuRWp0Q",
      facebook: "https://www.facebook.com/srimayapur.goshala.7",
      email: "srimayapurgoshala@gmail.com",
      ph: "+৯১ ৯৪৭৪৩ ৫৬১০৮",
      image: "https://www.iskconbangla.com/iframe/jjj/img/goshala.png",
    },

    {
      id: "kkk",
      title: "অনুসরণ করুন: টিও ভি পি",
      desc: "বি: দ্র: আপনি যদি ইসকনের নতুন মন্দির সম্বন্ধে জানতে চান তাহলে যোগাযোগ করুন",
      web: "https://tovp.org/",
      youtube: "https://www.youtube.com/channel/UCKGM37VSqjM8WNuD2pyoxrw",
      facebook: "https://www.facebook.com/tovp.mayapur",
      email: "info@tovp.org",
      ph: "+৯১ (৩৪৭২) ২৪৫২৩৮",
      image: "https://www.iskconbangla.com/iframe/kkk/img/tovp.png",
    },

    {
      id: "lll",
      title: "অনুসরণ করুন: ইসকন নিউজ",
      desc: "বি: দ্র: ইসকন নিউজের মাধ্যমে জানুন সমগ্র বিশ্বের কৃষ্ণ ভাবনামৃত নিউজ।",
      web: "https://iskconnews.org/",
      youtube:
        "https://www.youtube.com/channel/UCvGFcGHY8-m3Dp-l5InP67g/videos",
      facebook: "https://www.facebook.com/ISKCONnews",
      email: "noreply@iskconnews.org",
      ph: false,
      image: "https://www.iskconbangla.com/iframe/lll/img/iskconnews.jpg",
    },

    {
      id: "mmm",
      title: "অনুসরণ করুন: শ্রী শ্রীমৎজয়পতাকা স্বামী",
      desc: "বি:দ্র: ইসকনের অন্যতম শিক্ষা ও দিক্ষা গুরু শ্রী শ্রীমৎজয়পতাকা স্বামী মহারাজের সাথে নিয়মিত যুক্ত থাবার জন্য যোগাযোগ করুন",
      web: "https://www.jayapatakaswami.com/",
      youtube: "https://www.youtube.com/channel/UCcINSh7PGNE_7wEXKeLFOiw",
      facebook: "https://www.facebook.com/JayapatakaSwami",
      email: "jayapataka.swami@pamho.net",
      ph: "+৯১ ৩৪৭২-২৪৫২৭৭",
      image: "https://www.iskconbangla.com/iframe/mmm/img/JPS.png",
    },

    {
      id: "000",
      title: "অনুসরণ করুন: মায়াপুর টিভি",
      desc: "বি: দ্র: আপনি যদি নিয়মিত ইসকন মন্দিরের আরতি, প্রবচন এবং উৎসব সরাসরি দেখতে ছান তাহলে যোগাযোগ করুন",
      web: "https://www.mayapur.tv/",
      youtube: "https://www.youtube.com/channel/UCypj9Vvizo4cCERfDFIG3zw",
      facebook: "https://www.facebook.com/Mayapur.tv/",
      email: "antardwip.mtv@gmail.com",
      ph: "০৩৪৭২ ২৪৫১৪০",
      image: "https://www.iskconbangla.com/iframe/hhh/img/mtv.png",
    },
  ];

  let textWithoutBrTags = "";
  // Check if api.full_newstext is defined before manipulating it
  if (api?.full_newstext) {
    // Remove <br> tags from the text
    textWithoutBrTags=  { __html: api?.full_newstext.replace(/\n/g, '') };
    // textWithoutBrTags=  { __html: api?.full_newstext };
    // textWithoutBrTags = api?.full_newstext.replace(/\n/g,  );
  }

  var year = new Date(parseInt(id));
  const moreYear = year.getFullYear();
  const footer = api.footer_ad;

  for (let i = 0; i < dt.length; i++) {
    const arotiElement = dt[i];
    const { id } = arotiElement;

    if (id === footer) {
      const { ph, image, facebook, email, youtube, web, desc, title } =
        arotiElement;
      return (
        <div>
          <div className="max-w-4xl border mx-auto bg-slate-50 rounded-xl  shadow-lg shadow-gray-300 my-5">
            {youtubeEm?.length > 0 ? (
              <div className="max-w-4xl border mx-auto max-h-[504px] mb-2">
                <iframe
                  className="w-full"
                  height="400"
                  src={youtubeEm}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ):<div className="max-w-4xl border mx-auto max-h-[504px] rounded-xl">
              <img
                src={`https://www.iskconbangla.com/img/${moreYear}_news/${api.image}`}
                alt=""
                className="w-full rounded-xl max-h-[420px]"
              />
            </div>}

            
            {/* <p className="p-4 text-slate-600" dangerouslySetInnerHTML={textWithoutBrTags}></p> */}
            <p className=" text-gray-400 text-sm pt-2 pl-4">{timeDifference}</p>
            {
              api?.full_newstext && <div className="newsDescription p-4 text-slate-600" dangerouslySetInnerHTML={textWithoutBrTags}></div>
           }           
          </div>
          {/* footer start */}

          <div className="max-w-md mx-auto bg-slate-50 rounded-xl text-slate-600 shadow-sm shadow-gray-200 my-6 p-2 ">
            <img src={image} alt="" className="mx-auto" />
            <h2 className="my-1">{title}</h2>
            <p className="my-1">{desc}</p>

            <div className="mt-2">
              {web ? (
                <a
                  href={web}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-800 underline flex items-center  pb-1 rounded-full  hover:text-blue-900 transition duration-300 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>

                  <span className="pl-2 ">বিস্তারিত তথ্য জানতে</span>
                </a>
              ) : null}
            </div>
            <div>
              {youtube ? (
                <a
                  href={youtube}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-800 flex underline items-center  pb-1 rounded-full  hover:text-blue-900 transition duration-300 ease-in-out"
                >
                  <FaYoutube className="font-bold text-2xl text-red-600" />

                  <span className="pl-2 "> ইউটিউবে বিস্তারিত তথ্য জানতে</span>
                </a>
              ) : null}
            </div>

            <div>
              {facebook ? (
                <a
                  href={facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-800 underline flex items-center  pb-1 rounded-full  hover:text-blue-900 transition duration-300 ease-in-out"
                >
                  <FaFacebookF className="font-bold text-xl" />

                  <span className="pl-2 ">ফেসবুকে বিস্তারিত তথ্য জানতে</span>
                </a>
              ) : null}
            </div>

            <div>
              {email ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="text-blue-800 flex items-center  pb-1 rounded-full  hover:text-blue-900 transition duration-300 ease-in-out">
                  <MdMarkEmailRead className="font-bold text-2xl" />

                  <span className="pl-2 ">{email}</span>
                </a>
              ) : null}
            </div>

            <div>
              {ph ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="text-blue-800 flex items-center  pb-1 rounded-full  hover:text-blue-900 transition duration-300 ease-in-out">
                  <FaPhoneAlt className="text-lg " />

                  <span className="pl-2 ">{ph}</span>
                </a>
              ) : null}
            </div>
          </div>

           {/* this section is related news section  */}

           <RelatedNews/>
        </div>
      );
    }
  }
  return null;
};

export default NewsDetls;

